@import '../../static/scss/variables.scss';
.MuiPaper-root-4 {
  box-shadow: none !important;
}

.ant-calendar-picker-input {
  width: 200px;
  padding: 0px;
}

.ant-calendar-range-picker-input {
  font-size: 12px;
  height: 25px;
}

.ant-calendar-picker {
  width: 200px;
}

.ant-calendar-picker-input {
  height: 28px !important;
}

.ant-calendar-picker-icon,
.ant-calendar-picker-clear {
  left: 180px;
  top: 10px !important;
}

.ant-tag-blue {
  color: #fff !important;
  background: #3a3abf !important;
  border-color: #3a3abf !important;
}

.ant-calendar-selected-start-date .ant-calendar-date {
  color: #fff !important;
  background: linear-gradient(60deg, #3a3abf, #3a3abf) !important;
  border: 1px solid transparent;
}

.ant-calendar-today .ant-calendar-date {
  color: #fdfdfd !important;
  background: #3a3abf !important;
  font-weight: 700;
  border-color: #3a3abf !important;
}
