@import '../../static/scss/variables.scss';

// .react-select__value-container {
//   border: 10px solid red !important;

// }
.select__single-value {
  font-weight: 500;
  font-size: 0.8rem;
  color: $grey !important;
}

.select__control--menu-is-open {
  border: 1.5px solid $primary !important;
}
.custom-z-index {
  z-index: 100;
}

// .select__control {
//   // width: 100%;
//   // &:hover {
//   //   border: 1px solid black !important;
//   // }
//   // border: 1px solid #bbb;
// }

.select__menu > div > div {
  &:hover {
    background-color: $primary-vlight;
  }
}

.select__option--is-selected {
  background-color: $primary !important;
}

.cursor-pointer {
  cursor: pointer;
}

.no-filter-text {
  font-size: 14px;
}

.css-oi2lme-control {
  min-height: 28px !important;
}

.select__value-container,
.select__indicators {
  height: 28px !important;
}

.css-15ufavx-control {
  min-height: 28px !important;
}

// .css-b8ldur-Input {
//   .select__input[style] {
//     display: none !important;
//   }
// }
