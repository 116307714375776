.ant-select-selection:focus {
  outline: none !important;
  border: 1px solid #282a3c !important;
  box-shadow: none !important;
}
.ant-select-selection:hover {
  outline: none !important;
  border: 1px solid #282a3c !important;
  box-shadow: none !important;
}

.ant-select-selection {
  outline: none !important;
  box-shadow: none !important;
  font-size: 0.8rem !important;
}

div {
  font-size: 0.8rem !important;
}

.ant-calendar-picker-input:focus {
  outline: none !important;
  border: 1px solid #282a3c !important;
  box-shadow: none !important;
}
.ant-calendar-picker-input:hover {
  outline: none !important;
  border: 1px solid #282a3c !important;
  box-shadow: none !important;
}

.ant-calendar-picker-input {
  outline: none !important;
  box-shadow: none !important;
  font-size: 0.8rem !important;
}
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
  color: white !important;
  background-color: #282a3c !important;
}
div {
  font-size: 0.8rem !important;
}
