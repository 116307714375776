@import './base.scss';

body {
  // font-family: $font-family;"
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue,
    sans-serif;
  background: #f8f8f8 !important;
  overflow: hidden;
  color: var(--p-text, #212b36);
}

.App {
  p {
    margin: 5px;
    font-size: 16px;
  }
  .h4 {
    font-size: 1.1rem;
  }
}
.headerMainTitle {
  font-size: 20px;
}

.mainBodyPart {
  background: $body-bg;
  height: 80vh;
}
